import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import * as jwt_decode from 'jwt-decode';
import { ActivatedRoute, Router } from '@angular/router';
import swal from 'sweetalert';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  authenticationState = new BehaviorSubject(false);
  userDept;
  constructor(private http: HttpClient, private router: Router) { }

  registerUser(RegisterData) {
    return this.http.post<any>(`https://api.beingabundance.com/api/register_small`, RegisterData)
      .subscribe(data => {
        // var decodedToken = jwt_decode(token);
        // console.log('decoded token is ', decodedToken);
        // this.dataSvc.setTokenData(decodedToken, token);
        // return user;
        if (data) {
          swal({
            title: "Registered Successfully",
            text: "Welcome to Abundance",
            icon: "success",
            timer: 3000
        });
          this.router.navigate(['/']);
        }

      })
  }
  login(LoginData) {
    console.log('user is ' + LoginData);
    return this.http.post<any>(`https://api.beingabundance.com/api/profile`, LoginData)
      .subscribe(data => {
        var datain =data;
        if(data.status===true ){
          var loggedData = data.data;
          var email = loggedData[0].email;
          localStorage.setItem('currentUser', email);
          this.authenticationState.next(true);
          if (email) {
            swal({
              title: "Welcome Back!",
              text: "You have Logged-in Successfully!",
              icon: "success",
              timer: 2000
          });
            this.router.navigate(['/']);
          }
  

        }
        else{
          swal("Sorry!", datain.message, "error");
        }
       
      })

  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.authenticationState.next(false);
  }

  isAuthenticated() {
    return this.authenticationState.value;
  }

  checkToken() {
    var token = localStorage.getItem('currentUser');
    console.log('token is ', token);
    if (token) {
      // var decodedToken = jwt_decode(token);
      // console.log('decoded token is ', decodedToken);
      this.authenticationState.next(true);
    } else {
      this.authenticationState.next(false);
    }
  }
}
