import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { FormControl } from '@angular/forms';

import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { SignupComponent } from './components/signup/signup.component'

import { ComponentsModule } from './components/components.module';
import { ExamplesModule } from './examples/examples.module';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { I1 } from './interceptors/interceptors';
import { CartService } from '../app/services/cartservice';
import { OpenResourcesComponent } from './open-resources/open-resources.component';
import { SnackbarService } from './services/snackbar.service';
import { SpinnerService } from './services/spinner.service';
import { I2 } from './interceptors/snackbar_interceptors';
import{LoginmodalComponent} from './components/loginmodal/loginmodal.component'
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    OpenResourcesComponent,
    LoginmodalComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    // FormControl,
    RouterModule,
    ComponentsModule,
    ExamplesModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [
    CartService,
    SpinnerService,
    SnackbarService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: I2,
      multi: true

    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: I1,
      multi: true
    },

  ],
  bootstrap: [AppComponent],

  entryComponents: [
    SignupComponent,
    LoginmodalComponent
  ],
})
export class AppModule { }
