import { Component, OnInit,Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.css']
})
export class PaymentModalComponent implements OnInit {
  @Input() public product;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {

    console.log(this.product);
  }

}
