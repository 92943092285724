import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class CardserviceService {

  constructor(private http: HttpClient) { }

  getprofile(emaildata) {
    return this.http.post<any>('https://api.beingabundance.com/api/profile', emaildata);
  }

  carddetails(cardDetails){
    return this.http.post<any>(`https://api.beingabundance.com/api/update_carddetails`, cardDetails);
  }


}
