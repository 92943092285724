import { Component, OnInit } from '@angular/core';
import { CardserviceService } from '../../services/cardservice.service'
import { HttpClient } from "@angular/common/http";
@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {
    CurrentUserMail;
    Profiledetails;
    constructor(private httpClient: HttpClient, private cardSvc: CardserviceService) { }

    ngOnInit() {

        if (localStorage.getItem('currentUser')) {
            this.CurrentUserMail = localStorage.getItem('currentUser');
            var arr = { email: this.CurrentUserMail };
            this.cardSvc.getprofile(arr).subscribe(data => {
                var DATA =data;
                this.Profiledetails=DATA.data[0]
            })
        }
    }



}
