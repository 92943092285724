import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { share } from 'rxjs/operators';
import swal from 'sweetalert';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  
  addExtraClass: boolean = false;
  private visible$ = new BehaviorSubject<boolean>(false);
  constructor() { }

  show(err) {
    swal("Sorry!", "The AJAX request failed!", "error");
  }

  showsuccess(successmsg) {
    // var x = document.getElementById("snackbar");
    // x.className = "show";
    // setTimeout(function(){ x.className = x.className.replace("show", "Welcome"); }, 3000);

      // swal("Good job!", "You clicked the button!", "success");
  
  }


}


