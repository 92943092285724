import { Component, OnInit } from '@angular/core';
import { CartService } from '../../services/cartservice';
import { HttpClientModule } from '@angular/common/http';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import{PaymentModalComponent} from '../payment-modal/payment-modal.component'
@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.css']
})
export class ResourcesComponent implements OnInit {

  Cartlist = [];
  product;
  constructor(
    private addCartService: CartService,
    private modalService: NgbModal
  ) { }


  ngOnInit(): void {
  }
  checkout(product) {

    const modalRef = this.modalService.open(PaymentModalComponent);
    modalRef.componentInstance.product = product;

  }
}
