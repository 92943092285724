import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
@Component({
  selector: 'app-open-resources',
  templateUrl: './open-resources.component.html',
  styleUrls: ['./open-resources.component.css']
})
export class OpenResourcesComponent implements OnInit {

  title = 'json-file-read-angular';
  selectedData: any = [];
  selectedCardContent: any = [];
  step;

  constructor(private httpClient: HttpClient) {
  }
  status: boolean = false;
  gameactivated: boolean = false;
  cardactivate1: boolean = false;

  ngOnInit(): void {
  }
  clickEvent() {
    this.status = !this.status;
  }
  activategame() {
    this.gameactivated = true;
    this.activatecard();
  }

  activatecard() {
    var cardvalue = Math.floor(Math.random() * 6) + 1;
    console.log(cardvalue);
    this.cardactivate1 = true;
  
    this.httpClient.get("assets/cardcontent.json").subscribe(data => {
      console.log(data);
      this.selectedData = data;
      this.selectedCardContent = this.selectedData[cardvalue]
      console.log(this.selectedCardContent);
    })
  }

}
